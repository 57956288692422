'use client';

interface SidebarOpenButtonProps {
  sidebarId: string;
}

const SidebarOpenButton = ({ sidebarId }: SidebarOpenButtonProps) => {
  const handleClick = () => {
    document.getElementById(sidebarId)?.click();
  };

  return (
    <button
      id="sidebar-open-button"
      type="button"
      onClick={handleClick}
      className="btn btn-circle btn-ghost lg:hidden"
      aria-label="open sidebar"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="size-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16M4 18h7"
        />
      </svg>
    </button>
  );
};

export default SidebarOpenButton;
