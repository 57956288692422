'use client';

import React from 'react';

interface SidebarCloseButtonProps {
  sidebarId: string;
}

const SidebarCloseButton = ({ sidebarId }: SidebarCloseButtonProps) => {
  const handleClick = () => {
    document.getElementById(sidebarId)?.click();
  };

  return (
    <button
      onClick={handleClick}
      type="button"
      aria-label="close sidebar"
      className="drawer-overlay"
    />
  );
};

export default SidebarCloseButton;
